import './tailwind.min.css';
import './styles2.css';
import React, { useState } from 'react';

interface Game {
    id: number;
    image: string;
    alt: string;
    title: string;
    subtitle: string;
    descriptionJP: string;
    descriptionEN: string;
    platforms: string[];
    iosId: string;
    androidId: string;
}

const games: Game[] = [
    {
        id: 1,
        image: "image/image_1.png",
        alt: "Cat Tower",
        title: "Cat Tower",
        subtitle: "Puzzle Friends",
        descriptionJP: "どこでも楽しめる簡単パズルゲーム。100万ダウンロード突破！",
        descriptionEN: "Easy-to-play puzzle game for everyone. Over 1M downloads!",
        platforms: ["iOS", "Android"],
        iosId: "6627344472",
        androidId: "com.example.puzzlefriends"
    },
    {
        id: 2,
        image: "image/image_2.png",
        alt: "ファームライフ",
        title: "ファームライフ",
        subtitle: "Farm Life",
        descriptionJP: "のんびり育てる農園ゲーム。かわいいキャラクター一緒に園芸を楽しもう。",
        descriptionEN: "Relaxing farming game with cute characters. Enjoy gardening at your own pace.",
        platforms: ["iOS", "Android", "Steam"],
        iosId: "6627344472",
        androidId: "com.example.farmlife"
    },
    {
        id: 3,
        image: "image/image_3.png",
        alt: "タイムチャレンジ",
        title: "タイムチャレンジ",
        subtitle: "Time Challenge",
        descriptionJP: "瞬間的な判断が勝負爽快アクションゲーム！",
        descriptionEN: "Exhilarating action game where quick decisions matter!",
        platforms: ["iOS", "Android"],
        iosId: "6627344472",
        androidId: "com.example.timechallenge"
    }
];

function GameCard({ game }: { game: Game }) {
    return (
        <div className="game-card bg-white rounded-lg shadow-lg overflow-hidden">
            <img src={game.image} alt={game.alt} className="w-full h-48 object-cover" />
            <div className="p-6">
                <h2 className="text-xl font-bold text-gray-900 mb-2 title-text">
                    {game.title}
                    <span className="block text-sm sub-text">{game.subtitle}</span>
                </h2>
                <p className="description-text">{game.descriptionJP}</p>
                <p className="description-text">{game.descriptionEN}</p>
                <div className="flex flex-wrap">
                    {game.platforms.map((platform: string) => (
                        <span key={platform} className="platform-tag">{platform}</span>
                    ))}
                </div>
                <div className="flex space-x-4 mt-4">
                    <a href={`https://apps.apple.com/us/app/id${game.iosId}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                        iOS Store
                    </a>
                    <a href={`https://play.google.com/store/apps/details?id=${game.androidId}`} target="_blank" rel="noopener noreferrer" className="text-green-500 hover:underline">
                        Google Play
                    </a>
                </div>
            </div>
        </div>
    );
}

function GameSite() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // メール本文をエンコード
        const body = encodeURIComponent(
            `Name: ${name}\n\nMessage:\n${message}`
        );
        // メールを開く
        window.location.href = `mailto:sales@nex-a.net?subject=Contact from Website&body=${body}`;
        setIsModalOpen(false);
    };

    return (
        <div className="min-h-screen bg-[#262631]">
            {/* ヘッダー */}
            <header className="bg-white shadow-sm sticky top-0 z-50">
                <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16">
                        <div className="flex items-center">
                            <h1 className="title-text">NexA LLC.</h1>
                        </div>
                        <div className="flex space-x-4">
                            <a href="#" className="header-item flex items-center space-x-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                    <polyline points="9 22 9 12 15 12 15 22" />
                                </svg>
                                <span>Home</span>
                            </a>
                            <button onClick={toggleModal} className="text-gray-700 hover:text-gray-900 flex items-center space-x-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <rect width="20" height="16" x="2" y="4" rx="2" />
                                    <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                                </svg>
                                <span>Contact</span>
                            </button>
                            <a href="https://www.nex-a.net/" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-gray-900 flex items-center space-x-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                                    <polyline points="15 3 21 3 21 9" />
                                    <line x1="10" x2="21" y1="14" y2="3" />
                                </svg>
                                <span>Company</span>
                            </a>
                        </div>
                    </div>
                </nav>
            </header>

            {/* メーダル */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                        <h3 className="text-xl font-bold text-gray-900 mb-4">お問い合わせ</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2">お名前</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="w-full p-2 border rounded text-gray-900"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 mb-2">メッセージ</label>
                                <textarea
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    className="w-full p-2 border rounded h-32 text-gray-900"
                                    required
                                />
                            </div>
                            <div className="flex justify-end space-x-2">
                                <button
                                    type="button"
                                    onClick={toggleModal}
                                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                >
                                    キャンセル
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    メールを作成
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* メインコンテンツ */}
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="text-center mb-12">
                    <h2 className="title-text our-games">Our Games</h2>
                    <p className="sub-text">Simple, Fun, for Everyone</p>
                </div>

                {/* ゲーム一覧 */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {games.map(game => (
                        <GameCard key={game.id} game={game} />
                    ))}
                </div>
            </main>

            {/* フッター */}
            <footer className="bg-gray-800 text-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                    <div className="text-center">
                        <p>&copy; 2024 NexA LLC. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default GameSite;