// import './styles.css'; // グローバルスタイルのインポート

import React from 'react';
// import logo from './logo.svg';
import './App.css';
import Website from './Website';
import GameSite from './GameSite';

const App: React.FC = () => {
  return (
    <div>
      {/* <Website /> */}
      <GameSite />
    </div>
  );
};

export default App;
